<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">
          <a
            href="#"
            class="btn btn-primary btn-xs float-right"
            @click.prevent="show_modal = true"
            ><i class="lni-plus mr-2"></i>Request New</a
          >
          Sender ID
        </h4>
        <table class="table table-striped mt-4">
          <thead>
            <tr>
              <th>#</th>
              <th>Code</th>
              <th>Status</th>
              <th>Default</th>
              <th>Application Doc</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(sender, i) in sender_ids" :key="`sender-${i}`">
              <td>{{ i + 1 }}</td>
              <td>{{ sender.code }}</td>
              <td>{{ sender.status }}</td>
              <td>{{ sender.default ? "yes" : "no" }}</td>
              <td>
                <a :href="sender.document" class="btn btn-secondary btn-xs" target="_blank"><i class="lni-download mr-2"></i> Download</a>
              </td>
              <td>
                <a
                  href="#"
                  @click.prevent="setStatus('approved', sender)"
                  class="btn btn-primary btn-xs mx-1"
                  v-if="sender.status == 'pending' && user.role == 'admin'"
                  >Approve</a
                >
                <a
                  href="#"
                  @click.prevent="setStatus('denied', sender)"
                  class="btn btn-danger btn-xs mx-1"
                  v-if="sender.status == 'pending' && user.role == 'admin'"
                  >Deny</a
                >
                <a
                  href="#"
                  @click.prevent="show_payment = true; pay_for_sender = sender"
                  class="btn btn-primary btn-xs mx-1"
                  v-if="sender.status == 'approved'"
                  >Pay</a
                >
                <a
                  href="#"
                  @click.prevent="setStatus('default', sender)"
                  class="btn btn-primary btn-xs mx-1"
                  v-if="! sender.default && sender.status == 'active'"
                  >Make Default</a
                >
                <a
                  href="#"
                  @click.prevent="setStatus('suspended', sender)"
                  class="btn btn-primary btn-xs mx-1"
                  v-if="sender.status == 'active'"
                  >Suspend</a
                >
                <a
                  href="#"
                  @click.prevent="setStatus('active', sender)"
                  class="btn btn-primary btn-xs mx-1"
                  v-if="sender.status == 'suspended'"
                  >Reactivate</a
                >
              </td>
            </tr>
            <tr v-if="sender_ids.length == 0">
              <td colspan="5">
                <div class="alert alert-warning">You have no sender ids</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <modal :show="show_modal" @close="show_modal = false">
      <template slot="header">
        <h4 class="modal-title">Request Sender ID</h4>
      </template>
      <div class="alert alert-info">Download the document template below and attach your organization's letterhead and stamp. Fill it in and upload it below.</div>
      <div class="text-right mb-4">
        <a :href="template_link" target="_blank" class="btn btn-primary btn-xs"><i class="lni-files mr-2"></i>Download template</a>
      </div>
      <hr>
      <h5 class="mb-4">Fill the form below to continue</h5>
      <form @submit.prevent="request">
        <div class="form-group">
          <label>Sender ID <small>(CODE)</small></label>
          <input
            type="text"
            placeholder="Eg, TRADESK"
            class="form-control"
            v-model="code"
          />
        </div>
        <div class="form-group">
          <label>Attach Document</label>
          <input
            type="file"
            placeholder="Attach file"
            class="form-control"
            ref="document"
          />
        </div>
        <div class="form-group text-right">
          <button class="btn btn-primary btn-sm">
            <i class="lni-save mr-2"></i>Save
          </button>
        </div>
      </form>
    </modal>

    <modal :show="show_payment" @close="show_payment = false">
      <template slot="header">
        <h4 class="modal-title">Pay for sender ID</h4>
      </template>
      <div class="mpesa">
        <div class="row">
          <div class="col-md-12">
            <div>
              <img src="/m-pesa.png" alt="mpesa" height="100px" />
            </div>
            <div class="manual">
              <p>Follow the instructions below:</p>
              <ol>
                <li>Go to m-pesa menu</li>
                <li>Select lipa na mpesa</li>
                <li>Select pay bill</li>
                <li>
                  Enter paybill number
                  <strong>{{ mpesa_paybill }}</strong>
                </li>
                <li>
                  Enter account number
                  <strong>{{ mpesa_account }}</strong>
                </li>
                <li>
                  Enter the amount
                  <strong>{{ mpesa_amount }}</strong>
                </li>
                <li>Enter your pin and send</li>
              </ol>
              <a href="#" @click.prevent="setStatus('active', pay_for_sender)" class="btn btn-primary btn-sm"><i class="lni-save mr-2"></i> Confirm</a>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      show_modal: false,
      show_payment: false,
      sender_ids: [{}],
      code: "",
      pay_for_sender: {}
    };
  },

  methods: {
    fetch() {
      this.$loader.start('main-content')

      this.$axios
        .get(`/api/v1/apps/${this.$route.params.id}/sender-ids`)
        .then((response) => {
          this.sender_ids = response.data;
          this.$loader.stop()
        });
    },

    request() {
      var data = new FormData();
      data.append("document", this.$refs.document.files[0]);
      data.append('code', this.code)

      this.$loader.start('main-content')
      this.$axios
        .post(`/api/v1/apps/${this.$route.params.id}/sender-ids`, data)
        .then(() => {
          this.fetch();
          this.show_modal = false;
          this.code = "";
          this.$loader.stop()
        });
    },

    setStatus(status, sender) {
      let data = {
        status: status,
      };
      this.$loader.start('main-content')

      this.$axios
        .post(
          `/api/v1/apps/${this.$route.params.id}/sender-ids/${sender.id}`,
          data
        )
        .then(() => {
          this.show_payment = false
          this.$loader.stop('main-content')
          this.fetch();
        });
    },
  },

  computed: {
    mpesa_paybill() {
      return 12345;
    },

    mpesa_account() {
      return 1234
    },

    mpesa_amount() {
      return 1234
    },

    user() {
      return this.$store.getters.getAuthUser
    },

    template_link() {
      return `${process.env.VUE_APP_API_URL}/media/sender_id_form.docx`
    }
  }
};
</script>
